import NotificationStore from "@/store/notification";
import { Button, Snackbar } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const SnackbarNewVersion = () => {
  const { show, message, update } = NotificationStore();
  const handleOnClose = useCallback(() => {
    update({ message: "" });
  }, [update]);
  const { t } = useTranslation();

  return (
    <Snackbar
      open={show}
      sx={{
        "& .MuiSnackbarContent-root": {
          flexDirection: "row",
          backgroundColor: "rgb(0, 4, 31)",
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      message={t(message)}
      action={
        <Button
          color="secondary"
          size="small"
          onClick={() => window.location.reload()}
        >
          {t("COMMON.RELOAD")}
        </Button>
      }
      onClose={() => handleOnClose()}
      ClickAwayListenerProps={{
        onClickAway: () => null,
      }}
    />
  );
};

export default SnackbarNewVersion;
