import { CompanyApi } from "@/api";
import { CreateCompanyPayload } from "@/api/companies";
import { InfoType } from "@/components/base/input-phone";
import useFormater from "@/hooks/useFormater";
import CompanyStore from "@/store/company";
import UiStore from "@/store/ui";
import {
  checkZipIsValid,
  formatZipByCountry,
  isPhoneValid,
  isValidEmail,
} from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CountryCode } from "libphonenumber-js";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { z } from "zod";

const useCompanyForm = () => {
  const { t } = useTranslation();
  const { internationalPhone } = useFormater();
  const [defaultCountry, setDefaultCountry] = useState<CountryCode>("CA");
  const { preferredLanguage } = UiStore();
  const { company_uid } = useParams();
  const navigate = useNavigate();
  const {
    disabledFields,
    conflictedFields,
    updateState: updateCompany,
  } = CompanyStore();

  const defaultValue = useMemo((): CreateCompanyPayload => {
    return {
      name: "",
      network_name: "",
      country_code: "CA",
      business_phone: "",
      mobile_phone: "",
      business_uid: "", //activity
      price_uid: "", // licence
      address: "",
      city: "",
      province_code: "",
      zip: "",
      user_firstname: "",
      user_lastname: "",
      user_email: "",
      login_send: false,
      lang: preferredLanguage === "fr-FR" ? "fr-CA" : preferredLanguage,
      uid: "",
      network_uid: "",
    };
  }, [preferredLanguage]);

  const isZipRequired = (value: string) => {
    return ((value || value === "") && !value) || value?.length < 1;
  };

  const isZipValid = (value: string, countryCode = formValue?.country_code) => {
    return (
      (value || value === "") && checkZipIsValid(value, countryCode)
    );
  };

  const schema = useMemo(() => {
    return z.object({
      name: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      network_name: z.string().optional(),
      business_phone: z
        .string()
        .optional()
        .refine((value) => isPhoneValid(value), {
          message: t("FORMS.ERROR.PHONE_INVALID"),
        }),
      mobile_phone: z
        .string()
        .optional()
        .refine((value) => isPhoneValid(value), {
          message: t("FORMS.ERROR.PHONE_INVALID"),
        }),
      business_uid: z.string().optional(),
      network_uid: z.string(),
      price_uid: z.string().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      country_code: z.string().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      address: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      city: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      zip: z.string().trim().optional(),
      province_code: z.string().optional(),
      uid: z.string().optional(),
      user_firstname: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      user_lastname: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      user_email: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .refine((value) => isValidEmail(value), {
          message: t("FORMS.ERROR.INVALID_FORMAT_EMAIL"),
        }),
      lang: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      login_send: z.boolean().default(false),
    });
  }, [company_uid, preferredLanguage, t]);

  const {
    control,
    formState,
    watch,
    setValue,
    trigger,
    handleSubmit,
    setError,
  } = useForm<CreateCompanyPayload>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const formValue = useWatch({ control });

  const isFormValid = useMemo(() => {
    let isValid = formState?.isValid;
    if (Object.keys(formState?.errors ?? {})?.length > 0) {
      isValid = false;
    }
    return isValid;
  }, [formState]);

  const validateZip = useCallback(
    (value: string, countryCode: CountryCode) => {
      const formattedZip = formatZipByCountry(value, countryCode);
      
      if (formattedZip || formattedZip === "") {
        setValue("zip", formattedZip);
        if (isZipRequired(formattedZip)) {
          setError("zip", {
            type: "required",
            message: t("FORMS.ERROR.FIELD_REQUIRED"),
          });
        } else if (!isZipValid(formattedZip, countryCode)) {
          setError("zip", {
            type: "validate",
            message: t("FORMS.ERROR.ZIP_INVALID"),
          });
        } else {
          trigger(["zip"]);
        }
      }
    },
    []
  );
  const handleAddressChange = useCallback(
    (data: any) => {
      setValue("address", data.address);
      setValue("city", data.city);
      trigger(["address", "city"]);
      if (data?.country !== "FR" && data?.country !== "BE") {
        setValue("province_code", data.province_code);
        trigger(["province_code"]);
      }
      validateZip(data?.zip, data?.country);
    },
    [trigger, setValue, validateZip]
  );

  const handleAddressInputValue = useCallback((address: string) => {
    setValue("address", address);
    trigger(["address"]);
  }, []);

  const handleInvitationChange = useCallback(() => {
    setValue("login_send", !formValue?.login_send);
    trigger(["login_send"]);
  }, [formValue]);

  const zipInputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validateZip(e.target.value, formValue?.country_code);
    },
    [validateZip, formValue?.country_code]
  );

  const handleBusinessPhone = useCallback(
    (info: InfoType, value: string) => {
      setValue("business_phone", value);
      trigger(["business_phone"]);
    },
    [setValue]
  );

  const handleMobilePhone = useCallback(
    (info: InfoType, value: string) => {
      setValue("mobile_phone", value);
      trigger(["mobile_phone"]);
    },
    [setValue]
  );

  const handleNetworkName = useCallback(
    (network_name: string) => {
      setValue("network_name", network_name);
    },
    [setValue]
  );

  const provinceCodeHandler = useCallback(
    (province: any) => {
      setValue("province_code", province?.value);
      if (formValue?.country_code !== "FR" && formValue?.country_code !== "BE") {
        if (!province?.value) {
          setError("province_code", {
            type: "manual",
            message: t("FORMS.ERROR.FIELD_REQUIRED"),
          });
        }
      }
      trigger(["province_code"]);
    },
    [formValue?.country_code, setValue]
  );

  const resetTriggerAddress = useCallback(() => {
    setValue("city", "");
    setValue("zip", "");
    setValue("address", "");

    setValue("province_code", "");

    if (formValue?.country_code !== "FR" && formValue?.country_code !== "BE") {
      setError("province_code", {
        type: "manual",
        message: t("FORMS.ERROR.FIELD_REQUIRED"),
      });
    }
    setError("zip", {
      type: "manual",
      message: t("FORMS.ERROR.FIELD_REQUIRED"),
    });

    trigger(["city", "address"]);
  }, [formValue?.country_code]);

  const updateAddress = useCallback((company) => {
    setTimeout(() => {
      setValue("zip", company?.zip ?? "");
      setValue("city", company?.city ?? "");
      setValue("address", company?.address ?? "");
      if (company?.country_code !== "FR" && company?.country_code !== "BE") {
        setValue("province_code", company?.province_code ?? "");
      }
      trigger();
    }, 200);
  }, []);

  const updateValues = useCallback((company) => {
    setValue("user_email", company?.user_email ?? "");
    setValue("user_firstname", company?.user_firstname ?? "");
    setValue("user_lastname", company?.user_lastname ?? "");
    setValue("uid", company?.uid ?? "");
    setValue("country_code", company?.country_code ?? "CA");
    setValue("network_name", company?.network_name ?? "");
    setValue("price_uid", company?.price_uid ?? "");
    setValue("uid", company?.uid ?? "");
    setValue(
      "business_phone",
      internationalPhone(
        company?.business_phone,
        company?.country_code ?? "CA"
      ) ?? ""
    );
    setValue(
      "mobile_phone",
      internationalPhone(
        company?.mobile_phone,
        company?.country_code ?? "CA"
      ) ?? ""
    );
    setValue("name", company?.name ?? "");
    setValue("business_uid", company?.business_uid ?? "");
    setValue("lang", company?.lang ?? "");
    setValue("network_uid", company?.network_uid ?? "");
  }, []);

  useEffect(() => {
    if (defaultCountry !== formValue?.country_code) {
      resetTriggerAddress();
      setDefaultCountry(formValue?.country_code);
    }
  }, [formValue?.country_code]);

  useEffect(() => {
    if (disabledFields) {
      const copyFields = structuredClone(disabledFields);
      delete copyFields["network_name"];
      const arrayFromObjects = Object.entries(copyFields || {});
      (arrayFromObjects || []).forEach(([key, value]) => {
        return setValue(key as any, value);
      });
    }
  }, [disabledFields]);

  useEffect(() => {
    if (company_uid) {
      const fetchCompany = async () => {
        updateCompany({
          isLoading: true,
        });
        const company = await CompanyApi.getCompany({ company_uid });
        if (company?.error === "OBJECT_NOT_FOUND") {
          navigate("/accounts");
        } else if (company) {
          updateValues(company);
          updateAddress(company);
          updateCompany({
            disabledFields: {
              price_uid: company?.price_uid ?? "",
              user_email: company?.user_email ?? "",
              user_lastname: company?.user_lastname ?? "",
              user_firstname: company?.user_firstname ?? "",
              network_name: company?.network_name ?? "",
            },
            companyData: company,
            isLoading: false,
          });
          setValue("login_send", company?.is_login_sent);
        }
      };
      fetchCompany();
    }
  }, [company_uid]);

  useEffect(() => {
    if (conflictedFields?.length) {
      if (conflictedFields.includes("user_email")) {
        setError("user_email", {
          type: "manual",
          message: t("FORMS.ERROR.SAME_EMAIL"),
        });
      }
      if (conflictedFields.includes("name")) {
        setError("name", {
          type: "manual",
          message: t("COMPANIES.CREATE.EXIST"),
        });
      }
    }
  }, [conflictedFields]);

  useEffect(() => {
    return () => {
      updateCompany({
        disabledFields: null,
        companyData: null,
        isLoading: false,
        prices: [],
      });
    };
  }, []);

  return {
    control,
    isFormValid,
    formValue,
    formState,
    adminId: company_uid,
    watch,
    setValue,
    handleAddressChange,
    handleAddressInputValue,
    handleInvitationChange,
    zipInputHandler,
    handleBusinessPhone,
    handleMobilePhone,
    handleSubmit,
    handleNetworkName,
    provinceCodeHandler,
  };
};

export default useCompanyForm;
